// src/constant/url_api.js
export const host = process.env.REACT_APP_API_HOST;

export const api_url = {
  projet: {
    nouveau: "/api/nouveau-projet",
    liste: "/api/liste-projet",
    listeParTache: "/api/liste-projet-par-tache",
    get: "/api/get-projet/",
    suppression: "/api/supprimer-projet",
    statistiques: "/api/projet/statistiques",
  },

  tache: {
    nouveau: "/api/nouveau-tache",
    liste: "/api/liste-tache",
    get: "/api/get-tache/",
    encours: "/api/tache/en/cours",
    avenir: "/api/tache/a/venir",
    terminer: "/api/tache/terminer",
    suppression: "/api/supprimer-tache",
    statistique: "/api/statistique-tache",
    updateZenno: "/api/tache/retour/zenno",
  },
  configtache: {
    nouveau: "/api/nouveau-config-tache",
    liste: "/api/liste-config-tache",
    get: "/api/get-config-tache/",
    suppression: "/api/supprimer-config-tache",
  },
  configthematique: {
    nouveau: "/api/nouveau-config-thematique",
    liste: "/api/liste-config-thematique",
    get: "/api/get-config-thematique/",
    suppression: "/api/supprimer-config-thematique",
  },

  chart: {
    projet: "/api/taches-par-projet/",
    type_tache_projet: "/api/get-taches-par-type-et-projet/",
  },
};
