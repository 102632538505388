import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { host, api_url } from "../../constant/url_api";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const TacheProjetDonutsChart = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const projectFilter = query.get("project");

  const [chartsData, setChartsData] = useState({});
  const [projectDetails, setProjectDetails] = useState({
    nom_projet: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projectFilter) {
          const response = await axios.get(
            host + api_url.chart.type_tache_projet + projectFilter
          );
          const data = response.data;

          setProjectDetails({
            nom_projet: data.nom_projet,
          });

          const formattedData = formatChartData(data);
          setChartsData(formattedData);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchData();
  }, [projectFilter]);

  const formatChartData = (data) => {
    const charts = {};

    Object.keys(data).forEach((typeTache) => {
      if (typeTache !== "nom_projet" && data[typeTache]) {
        const labels = [
          `Tâches en cours (${data[typeTache].taches_en_cours})`,
          `Tâches futures (${data[typeTache].taches_futures})`,
          `Tâches terminées (${data[typeTache].taches_terminees})`,
        ];

        charts[typeTache] = {
          labels: labels,
          datasets: [
            {
              data: [
                data[typeTache].taches_en_cours,
                data[typeTache].taches_futures,
                data[typeTache].taches_terminees,
              ],
              backgroundColor: ["#FFC107", "#DC3545", "#28A745"],
              label: typeTache,
            },
          ],
        };
      }
    });

    return charts;
  };

  return (
    <div>
      <div className="container">
        <div className="row row-cols-3">
          {Object.keys(chartsData).length > 0 ? (
            Object.keys(chartsData).map((typeTache) => {
              const chartOptions = {
                layout: {
                  padding: {
                    left: 15, // Padding avoiding superposition !!!
                  },
                },
                plugins: {
                  title: {
                    display: true,
                    text: typeTache,
                    position: "top",
                    align: "center",
                    padding: {
                      top: 15,
                      // bottom: 10,
                    },
                    font: {
                      size: 16,
                      weight: "bold",
                    },
                  },
                  legend: {
                    position: "left",
                    align: "center",
                    labels: {
                      boxWidth: 12,
                      padding: 15,
                    },
                    onHover: (event) => {
                      event.native.target.style.cursor = "pointer";
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: function (tooltipItem) {
                        return tooltipItem.label;
                      },
                    },
                  },
                },
              };

              return (
                <div className="col" key={typeTache}>
                  <div className="chart-container-custom">
                    <Doughnut
                      data={chartsData[typeTache]}
                      options={chartOptions}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TacheProjetDonutsChart;
