import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import DisplayModal from "../../components/Modals/DisplayModal";
import { ModalContext } from "../../context/ModalContext";
import { useState, useEffect } from "react";
import { host, api_url } from "../../constant/url_api";
import axios from "axios";
import TableTachesPage from "../Tables/TableTachesPage";
import NouveauTachePage from "../Buttons/NouveauTachePage";
import { useLocation } from "react-router-dom";
import ProjetDonutsChart from "../../components/Charts/ProjetDonutsChart";
import TacheProjetDonutsChart from "../../components/Charts/TacheProjetDonutsChart";

function Taches() {
  const [show, setShow] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [listUpdate, setListUpdate] = useState(false);
  const [listeTache, setListeTache] = useState(null);
  const [id_modifer, setId_modifier] = useState(null);
  const location = "tache";

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const projectFilter = query.get("project");

  const closeModal = () => {
    setShow(false);
    setId_modifier(null);
  };

  const isValidProjectFilter = (filter) => {
    return filter !== null && filter !== undefined && filter.trim() !== "";
  };

  //initialisation
  useEffect(() => {
    axios
      .get(host + api_url.tache.liste)
      .then((res) => {
        setListeTache([]);
        var result = res.data;
        if (result != null) {
          result.forEach((element) => {
            setListeTache((current) => [...current, element]);
          });
        } else {
          setListeTache([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // onUpdate
  useEffect(() => {
    if (listUpdate) {
      axios
        .get(host + api_url.tache.liste)
        .then((res) => {
          setListeTache([]);
          var result = res.data;
          if (result != null) {
            result.forEach((element) => {
              setListeTache((current) => [...current, element]);
            });
          } else {
            setListeTache([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setListUpdate(false);
    }
  }, [listUpdate]);

  return (
    <>
      <ModalContext.Provider
        value={{
          show,
          setShow,
          typeModal,
          setTypeModal,
          closeModal,
          listUpdate,
          setListUpdate,
          listeTache,
          setListeTache,
          id_modifer,
          setId_modifier,
          location,
        }}
      >
        <Header />
        <div className="d-flex flex-row">
          <Sidebar />
          <div className="container-fluid p-3 mt-3">
            <div className="row p-2">
              <div className="col-md-12">
                <div className="row p-2">
                  <div className="col-md-6">
                    <h1 className="titre-page">Mes Tâches</h1>
                  </div>
                  <div className="col-md-6 d-flex flex-row align-items-center justify-content-end">
                    <NouveauTachePage />
                  </div>
                </div>
                {/* API */}
                {isValidProjectFilter(projectFilter) && (
                  <>
                    <div className="row p-2">
                      <div className="col-md-6">
                        <h3 className="titre-page">
                          Statistiques du projet {projectFilter}
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="alert alert-success" role="alert">
                          <strong>Statistiques par projet : </strong>
                          <a
                            // href={`${
                            //   host + api_url.chart.projet + projectFilter
                            // }`}
                            href={`https://taskm.yopyo.com/php/public/api/taches-par-projet/${projectFilter}`}
                            target="_blank"
                          >
                            https://taskm.yopyo.com/php/public/api/taches-par-projet/
                            {projectFilter}
                          </a>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="alert alert-success" role="alert">
                          <strong>
                            Statistiques par projet et chaque type tâches :{" "}
                          </strong>
                          <a
                            href={`https://taskm.yopyo.com/php/public/api/get-taches-par-type-et-projet/${projectFilter}`}
                            // href={`${
                            //   host +
                            //   api_url.chart.type_tache_projet +
                            //   projectFilter
                            // }`}
                            target="_blank"
                          >
                            https://taskm.yopyo.com/php/public/api/get-taches-par-type-et-projet/
                            {projectFilter}
                          </a>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="alert alert-warning" role="alert">
                          <strong>Statistiques complètes : </strong>{" "}
                          <a
                            href={`https://taskm.yopyo.com/php/public/api/projet/statistiques`}
                            // href={`${host + api_url.projet.statistiques}`}
                            target="_blank"
                          >
                            https://taskm.yopyo.com/php/public/api/projet/statistiques
                          </a>
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                    <div className="alert alert-warning" role="alert">
                      <strong>Mise à jour: </strong>
                      <a href="#" target="_blank">
                        https://taskm.yopyo.com/php/public/api/tache/retour/zenno?idtask=19&statut=echec
                      </a>
                    </div>
                  </div> */}
                    </div>
                  </>
                )}
                {/* API */}
                {/* Chart */}
                <div class="row">
                  <div class="col-6 col-md-4">
                    <ProjetDonutsChart />
                  </div>
                  <div class="col-md-8">
                    <TacheProjetDonutsChart />
                  </div>
                </div>
                {/* END OF Chart */}
                <TableTachesPage />
              </div>
            </div>
          </div>
        </div>
        <DisplayModal />
      </ModalContext.Provider>
    </>
  );
}

export default Taches;
